@import "bootstrap/dist/css/bootstrap.min.css";
@import "react-datepicker";
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-container {
  flex-grow: 1;
}

.flex-1 {
  flex: 1;
}

.modal-backdrop {
  height: 100% !important;
  width: 100% !important;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.min-width-10-rem {
  min-width: 10rem;
}

.loading:after {
  animation: spin-clockwise 0.7s linear infinite;
  content: " ";
  display: inline-block;
  vertical-align: sub;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-left: 0.5em;
  border: 2px solid hsla(0, 0%, 100%, 0.33333);
  border-left-color: white;
}

.loading-dark:after {
  animation: spin-clockwise 0.7s linear infinite;
  content: " ";
  display: inline-block;
  vertical-align: sub;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-left: 0.5em;
  border: 2px solid hsla(0, 0%, 0%, 0.33333);
  border-left-color: black;
}

.loader {
  animation: spin-clockwise 0.7s linear infinite;
  content: " ";
  display: inline-block;
  vertical-align: sub;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-left: 0.5em;
  border: 2px solid hsla(0, 0%, 100%, 0.33333);
  border-left-color: white;
}

.loader-dark {
  animation: spin-clockwise 0.7s linear infinite;
  content: " ";
  display: inline-block;
  vertical-align: sub;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-left: 0.5em;
  border: 2px solid hsla(0, 0%, 0%, 0.33333);
  border-left-color: black;
}

.min-width-max-content {
  min-width: max-content;
}

.mobile-only {
  display: none !important;
}

.mobile-none {
  display: block !important;
}

.mobile-none-flex {
  display: flex !important;
}

.mobile-only-table-cell {
  display: none !important;
}

.mobile-none-table-cell {
  display: table-cell !important;
}

@media screen and (max-width: 950px) {
  .mobile-only {
    display: block !important;
  }

  .mobile-none {
    display: none !important;
  }

  .mobile-only-table-cell {
    display: table-cell !important;
  }

  .mobile-none-table-cell {
    display: none !important;
  }

  .mobile-none-flex {
    display: none !important;
  }
}

.icon-button {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button-small {
  height: 30px;
  width: 30px;
  padding: 0;
}

.icon-button.loading:after {
  margin: 0;
}

.icon-button.loading-dark:after {
  margin: 0;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.border-rounded {
  border-radius: 1rem;
}

.height-max-content {
  height: max-content;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.width-max-content {
  width: max-content;
}

.grid-two-columns-min-auto {
  grid-template-columns: min-content auto;
}

.grid-two-columns-max-auto {
  grid-template-columns: max-content auto;
}

.vertical-middle {
  vertical-align: middle;
}

.visible-on-table-row-hover {
  visibility: hidden !important;

  *:hover > td & {
    visibility: visible !important;
  }

  @media screen and (max-width: 1024px) {
    visibility: visible !important;
  }
}

.visible-on-table-cell-hover {
  visibility: hidden !important;

  td:hover & {
    visibility: visible !important;
  }

  @media screen and (max-width: 950px) {
    visibility: visible !important;
  }
}

.select-none {
  user-select: none;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.white-space-nowrap {
  white-space: nowrap;
}

.break-word {
  word-break: break-word;
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(#dee2e6, 0.3);
    border: 2px solid transparent;
    border-radius: 9px;
    background-clip: content-box;

    &:hover {
      background-color: rgba(#dee2e6, 0.5);
    }
  }
}

.border-semi-round {
  border-radius: 40px;
}

.font-weight-bold {
  font-weight: bold;
}

.width-50-mobile-100 {
  width: 50%;

  @media screen and (max-width: 950px) {
    width: 100%;
  }
}

.opacity-70-on-hover {
  &:hover {
    opacity: 70%;
  }
}

.disabled-button-primary {
  color: #fff;
  opacity: 0.65;
  pointer-events: none;
}

.transparent-input {
  text-align: end;
  background-color: transparent;
  outline: none;
  border: 0;
  margin-right: 0;
  padding: 0;
  font-weight: bold;
  color: green;
}

._text-primary {
  color: #2b8afc;
}

.grid-max-1fr {
  grid-template-columns: max-content 1fr;
}

.rotate-90 {
  transform: rotate(90deg);
}

.z-index-max {
  z-index: 100000;
}

.background-shadow {
  background-color: rgba(0, 0, 0, 0.5);
}

._z-max {
  z-index: 9999;
}
