.custom-modal {
  min-width: 50vw;
  max-height: 90vh;
  z-index: 10000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.min-height-50-vh {
    min-height: 50vh;
  }

  .close-button {
    border: 2px solid white;
    color: white;
    height: 25px;
    width: 25px;
    font-size: 12px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 950px) {
  .custom-modal {
    width: 90vw;
  }
}

.custom-modal-body {
  max-height: 80vh;
}
