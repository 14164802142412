.orders-summary {
  .extra-expenses-cell {
    &:hover {
      background-color: rgba(255, 255, 255, 0.075);
    }
  }

  .extra-expenses-popover {
    position: absolute;
    background-color: #212529;
    top: 120%;
    right: 0;
    display: flex;
    z-index: 1;

    .extra-expenses-input {
      width: 10rem;
    }

    .extra-expenses-button {
      border-top: 1px solid #495057;
      border-right: 1px solid #495057;
      border-bottom: 1px solid #495057;
      border-left: 0;
      border-radius: 0 0.375rem 0.375rem 0;
    }
  }

  .filters-wrapper {
    width: 100%;
    display: inline-grid;
    grid-template-columns: max-content 1fr max-content 1fr max-content 1fr max-content 1fr;

    @media screen and (max-width: 950px) {
      display: grid;
      grid-template-columns: max-content auto;
    }
  }
}
