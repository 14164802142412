.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input {
  width: 100%;
  background-color: #212529;
  border: 1px solid #495057;
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
}

.react-datepicker {
  background-color: #212529 !important;
}

.react-datepicker__header {
  background-color: #212529 !important;
}

.react-datepicker-time__header {
  color: #fff !important;
}

.react-datepicker__time-list-item {
  background: #212529 !important;
  color: #fff !important;
}

.react-datepicker__time-list-item:hover {
  background: #fff !important;
  color: #212529 !important;
}

.react-datepicker__time-list-item--disabled {
  color: #ccc !important;
  pointer-events: none !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
  color: #fff !important;
}

.react-datepicker__day:hover {
  color: black !important;
}

.react-datepicker__day--disabled {
  color: #6c757d !important;
}

.react-datepicker__day--disabled:hover {
  color: #6c757d !important;
}

.react-datepicker__day--keyboard-selected {
  color: black !important;
}

.react-datepicker__day--in-range:not(
    .react-datepicker__day--in-selecting-range
  ) {
  background-color: rgba(#216ba5, 0.5) !important;
}
