.order-container {
  width: 75%;
}

.order-filters-wrapper {
  width: 100%;
  display: inline-grid;
  grid-template-columns: max-content 1fr max-content 1fr max-content 1fr;
}

.admin-controls {
  display: inline-grid;
  grid-template-columns: max-content 1fr max-content 1fr;
  align-items: center;
}

.cleaner-controls {
  justify-content: end;
}

@media screen and (max-width: 950px) {
  .order-filters-wrapper {
    display: grid;
    grid-template-columns: max-content auto;
  }

  .select-wrapper {
    width: 100%;
  }

  .order-header {
    flex-direction: column;
  }

  .admin-controls {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
  }

  .cleaner-controls {
    justify-content: center;
  }

  .refuse-error {
    font-size: 12px;
  }

  .admin-buttons {
    width: 100%;
  }

  .order-wrapper {
    flex-direction: column;
  }

  .order-container {
    width: 100%;
  }

  .order-additional-block {
    width: 100%;
  }

  .order-title {
    width: 100%;
  }
}

.transportation-badge {
  border: 1px solid #495057;
  border-radius: 1rem;
}

.check-list-item::before {
  content: "•";
  display: inline-block;
  font-size: 16px;
  margin-right: 11px;
}

.check-list-item-checked::before {
  content: "✓";
  display: inline-block;
  font-size: 16px;
  margin-right: 11px;
}
