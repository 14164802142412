.navigation {
  background-color: lighten(#212529, 10%);
  box-shadow: rgba(#000, 0.5) 0 0 5px;
  transition: 0.3s all;

  &.navigation-visible {
    width: 30%;
  }

  &.navigation-invisible {
    width: 0;
  }

  .navigation-item {
    color: white;

    &.active {
      color: #0d6efd;
    }

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.7;
      color: #0d6efd;
    }
  }

  @media screen and (max-width: 950px) {
    &.navigation-visible {
      width: 60%;
    }

    &.navigation-invisible {
      width: 0;
    }
  }
}

.header-wrapper {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
}
