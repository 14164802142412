.discounts-wrapper {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media screen and (max-width: 950px) {
  .discounts-wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.edit-discount-wrapper {
  grid-template-columns: min-content auto;
}
