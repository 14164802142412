.blogs-wrapper {
  .blog {
    background: #ecf0ff;
    border-radius: 24px;

    &.main {
      width: 50%;

      @media screen and (max-width: 950px) {
        width: 100%;
      }
    }
  }

  .sliced-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  .category {
    border: 1px solid #5c5c5c;
    color: #5c5c5c;
    border-radius: 320px;
    padding: 0.5rem 1.25rem;
  }

  .title {
    font-size: 40px;
    line-height: 130%;
    color: #13277e;
    font-weight: bold;
  }

  .text-wrapper {
    margin: 0 190px;

    @media screen and (max-width: 950px) {
      margin: 0;
    }
  }

  .image {
    border-radius: 24px;

    @media screen and (max-width: 950px) {
      width: 30%;
    }
  }
}
