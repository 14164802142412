.rating-star {
    transition: all 0.2s ease-in-out;
}

.rating-star:hover {
    transform: scale(1.2);
}

.rating-star:active {
    transform: scale(1.4);
}

.rating-star.disabled {
    pointer-events: none;
}
