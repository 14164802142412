.login-wrapper {
  .locales-wrapper {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .login-subtitle {
    color: #848484;
  }

  .login-input {
    width: 30rem;
    padding: 0.75rem;
    background-color: #2b3035;
    border-radius: 5px;
    transition: background 0.15s ease, outline-color 0.15s ease,
      border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
    border: 1px solid #424353;
    outline: none;
    color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5), 0 1px 6px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    &:focus {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5), 0 1px 6px rgba(0, 0, 0, 0.25),
        0 0 0 3px hsla(216, 98%, 52%, 25%),
        0 1px 1px 0 rgba(255, 255, 255, 0.12);
      border: 1px solid hsla(216, 98%, 52%, 40%);
    }

    &:hover {
      border: 1px solid #0d6efd;
    }

    &.password {
      padding-right: 3rem;
    }
  }

  .show-password-icon {
    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

//@property --gradX {
//  syntax: "<percentage>";
//  initial-value: 0%;
//  inherits: true;
//}
//
//@property --gradY {
//  syntax: "<percentage>";
//  initial-value: 0%;
//  inherits: true;
//}
//
//.border-loading {
//  position: relative;
//  border-radius: 6px;
//}
//
//.border-loading::before {
//  @media screen and (min-width: 1024px) {
//    content: "";
//    position: absolute;
//    inset: -1px;
//    border: 2px solid transparent;
//    border-radius: 6px;
//
//    background-origin: border-box;
//    background-image: radial-gradient(
//                    ellipse at var(--gradX) var(--gradY),
//                    #fff,
//                    #fff 10%,
//                    transparent 40%
//    );
//    background-clip: border-box;
//    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
//      linear-gradient(#fff 0 0);
//    -webkit-mask-composite: xor;
//    mask-composite: exclude;
//
//    animation: borderRadial 3s linear infinite forwards;
//  }
//}
//
//@keyframes borderRadial {
//  0% {
//    --gradX: 0%;
//    --gradY: 0%;
//  }
//  25% {
//    --gradX: 100%;
//    --gradY: 0%;
//  }
//  50% {
//    --gradX: 100%;
//    --gradY: 100%;
//  }
//  75% {
//    --gradX: 0%;
//    --gradY: 100%;
//  }
//  100% {
//    --gradX: 0%;
//    --gradY: 0%;
//  }
//}
