.users-rating-popover {
  position: absolute;
  padding: 1rem;
  background-color: #212529;
  border: 1px solid #495057;
  border-radius: 1rem;
  top: 120%;
  right: 0;
  display: flex;
}
